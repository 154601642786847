exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-all-time-js": () => import("./../../../src/pages/all-time.js" /* webpackChunkName: "component---src-pages-all-time-js" */),
  "component---src-pages-app-new-js": () => import("./../../../src/pages/AppNew.js" /* webpackChunkName: "component---src-pages-app-new-js" */),
  "component---src-pages-hd-js": () => import("./../../../src/pages/hd.js" /* webpackChunkName: "component---src-pages-hd-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jrt-js": () => import("./../../../src/pages/jrt.js" /* webpackChunkName: "component---src-pages-jrt-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-past-seasons-js": () => import("./../../../src/pages/past-seasons.js" /* webpackChunkName: "component---src-pages-past-seasons-js" */),
  "component---src-pages-season-hist-js": () => import("./../../../src/pages/season-hist.js" /* webpackChunkName: "component---src-pages-season-hist-js" */),
  "component---src-pages-sign-out-js": () => import("./../../../src/pages/sign-out.js" /* webpackChunkName: "component---src-pages-sign-out-js" */),
  "component---src-pages-transfers-js": () => import("./../../../src/pages/transfers.js" /* webpackChunkName: "component---src-pages-transfers-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

